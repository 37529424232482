import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import {
  CHARLESTON_PHONE,
  CHARLESTON_PHONE_DISPLAY,
  NEW_YORK_DOWNTOWN_PHONE,
  NEW_YORK_DOWNTOWN_PHONE_DISPLAY,
  NEW_YORK_MIDTOWN_PHONE,
  NEW_YORK_MIDTOWN_PHONE_DISPLAY,
} from '../../data/site-data';

function OfficeListing() {
  return (
    <div className="section pt-5 pb-5">
      <div className="container">
        <div className="container-border-pale is-right pt-lg-8 pb-lg-8 mb-7 mb-lg-5">
          <div className="row">
            <div className="col-lg-7 mb-5 mb-lg-0">
              <StaticImage
                quality={90}
                className="img-fluid z-index-1"
                src="../../assets/img/ny-office-downtown.jpg"
                alt="New York immigration lawyer Pandev Law"
                placeholder="none"
                loading="eager"
              />
            </div>
            <div className="offset-lg-1 col-lg-3 d-lg-flex align-items-center">
              <div className="position-relative z-index-1">
                <h3 className="text-headline mb-5">
                  <Link
                    to="/new-york-immigration-lawyer-downtown/"
                    className="anchor-links-contact">
                    New York Immigration Office - Downtown
                  </Link>
                </h3>
                <p className="text-lead">One World Trade Center, Suite 8500</p>
                <p className="text-lead">New York, NY 10007</p>
                <p className="text-lead">
                  <a
                    className="link-phone-alt phone-new-york-downtown"
                    href={`tel:${NEW_YORK_DOWNTOWN_PHONE}`}>
                    {NEW_YORK_DOWNTOWN_PHONE_DISPLAY}
                  </a>
                </p>
                <Link
                  to="/new-york-immigration-lawyer-downtown/"
                  className="btn btn-secondary d-block d-md-inline-block mt-5">
                  MORE INFO
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container-border-pale is-left pt-lg-8 pb-lg-8 mb-7 mb-lg-5">
          <div className="row">
            <div className="offset-lg-1 col-lg-7 order-lg-12 mb-5 mb-lg-0">
              <StaticImage
                quality={90}
                src="../../assets/img/ny-office.jpg"
                alt="New York immigration lawyer Pandev Law"
                className="img-fluid z-index-1"
              />
            </div>
            <div
              className="
                    offset-lg-1
                    col-lg-3
                    order-lg-1
                    d-lg-flex
                    align-items-center
                ">
              <div className="position-relative z-index-1">
                <h3 className="text-headline mb-5">
                  <Link
                    to="/new-york-immigration-lawyer/"
                    className="anchor-links-contact">
                    New York Immigration Office - Midtown
                  </Link>
                </h3>
                <p className="text-lead">600 Third Avenue, Floor 2</p>
                <p className="text-lead">New York, NY 10016</p>
                <p className="text-lead">
                  <a
                    className="link-phone-alt phone-new-york-midtown"
                    href={`tel:${NEW_YORK_MIDTOWN_PHONE}`}>
                    {NEW_YORK_MIDTOWN_PHONE_DISPLAY}
                  </a>
                </p>
                <Link
                  to="/new-york-immigration-lawyer/"
                  className="btn btn-secondary d-block d-md-inline-block mt-5">
                  MORE INFO
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container-border-pale is-right pt-lg-8 pb-lg-8">
          <div className="row">
            <div className="col-lg-7 mb-5 mb-lg-0">
              <StaticImage
                quality={90}
                src="../../assets/img/ch-office.jpg"
                alt="Charleston immigration lawyer Pandev Law"
                className="img-fluid z-index-1"
              />
            </div>
            <div className="offset-lg-1 col-lg-3 d-lg-flex align-items-center">
              <div className="position-relative z-index-1">
                <h3 className="text-headline mb-5">
                  <Link
                    to="/charleston-immigration-lawyer/"
                    className="anchor-links-contact">
                    Charleston Immigration Office
                  </Link>
                </h3>
                <p className="text-lead">170 Meeting Street</p>
                <p className="text-lead">Charleston, SC 29401</p>
                <p className="text-lead">
                  <a
                    className="link-phone-alt phone-charleston"
                    href={`tel:${CHARLESTON_PHONE}`}>
                    {CHARLESTON_PHONE_DISPLAY}
                  </a>
                </p>
                <Link
                  to="/charleston-immigration-lawyer/"
                  className="btn btn-secondary d-block d-md-inline-block mt-5">
                  MORE INFO
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfficeListing;
