import React from 'react';
import Layout from '../components/layout';
import OfficeListing from '../components/contact/office-listing';

const headMeta = {
	title: 'Offices | Pandev Law',
	description:
		'Are you looking for one of our offices? Click here and find more contact info about our locations in New York and Charleston.',
	keywords: '',
};

const schema = `{}`;

function Offices({ location }) {
	return (
		<Layout headMeta={headMeta} location={location} footerSchema={schema}>
			<div className="container my-5 pt-4 pb-0 py-lg-10">
				<h1 className="text-hero">Our offices</h1>
			</div>
			<OfficeListing />
		</Layout>
	);
}

export default Offices;
